// require("@rails/ujs").start();
// require("@rails/activestorage").start();
// require("channels");

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";

import "channels";
import "chartkick/chart.js";

Rails.start();
ActiveStorage.start();

// STIMULUS CONTROLLERS
import "../controllers";

// INDEX.JS
import "src/cartwave";

require("trix");
require("@rails/actiontext");
